/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import appActionTypes from './App-ActionTypes';

export const loadAppShellSSR = (payload) => ({
    type: appActionTypes.MBP_UI_LOAD_APP_SERVER,
    payload,
});

export const loadAppShell = (payload) => ({
    type: appActionTypes.MBP_UI_LOAD_APP_CLIENT,
    payload,
});

export const setAppShellLoadState = (loaded) => ({
    type: appActionTypes.PAGE_SHELL_LOADED,
    payload: loaded,
});

export const setAppShellType = (type) => ({
    type: appActionTypes.MBP_UI_SSR_INIT,
    payload: type,
});

export const initSSR = (state) => ({
    type: appActionTypes.MBP_UI_SET_BOT_STATE,
    payload: state,
});

export const setBotState = (isBot) => ({
    type: appActionTypes.MBP_UI_SET_BOT_STATE,
    payload: isBot,
});

export const setSSRState = (loaded) => ({
    type: appActionTypes.MBP_UI_SET_SSR_STATE,
    payload: loaded,
});

export const setSSRHostname = (loaded) => ({
    type: appActionTypes.MBP_UI_SET_SSR_HOSTNAME,
    payload: loaded,
});

export const setSSRPath = (loaded) => ({
    type: appActionTypes.MBP_UI_SET_SSR_PATH,
    payload: loaded,
});

export const setSSRSearch = (loaded) => ({
    type: appActionTypes.MBP_UI_SET_SSR_SEARCH,
    payload: loaded,
});

export const setSSRDeviceType = (deviceType) => ({
    type: appActionTypes.MBP_UI_SET_SSR_DEVICE_TYPE,
    payload: deviceType,
});

export const closeMBPUIApp = () => ({
    type: appActionTypes.MBP_UI_CLOSE_APP,
});

export const setCurrentBrandId = (brandId) => ({
    type: appActionTypes.APP_SET_CURRENT_BRAND_ID,
    payload: brandId,
});

export const setCurrentUrlId = (urlId) => ({
    type: appActionTypes.APP_SET_CURRENT_URL_ID,
    payload: urlId,
});

export const getSearchResult = (searchValue) => ({
    type: appActionTypes.MBP_UI_APP_GET_SEARCH_RESULT,
    payload: searchValue,
});

export const setSearchResult = (suggestionsData) => ({
    type: appActionTypes.MBP_UI_APP_SET_SEARCH_DATA,
    payload: suggestionsData,
});

export const clearSearchResult = (suggestionsData) => ({
    type: appActionTypes.MBP_UI_APP_SET_SEARCH_ClEAR,
    payload: suggestionsData,
});

// Zipcode validation
export const setProductFilterZipcodeValidity = (bool) => ({
    type: appActionTypes.MBP_UI_SET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
    payload: bool,
});

export const validateProductFilterZipcode = (zipcode) => ({
    type: appActionTypes.MBP_UI_VALIDATE_PRODUCT_FILTER_ZIPCODE,
    payload: zipcode,
});

export const setVisibleSearchProductDetails = (products) => ({
    type: appActionTypes.MBP_UI_SET_VISIBLE_SEARCH_PRODUCT_DETAILS,
    payload: products,
});

export const resetProductFilterZipcodeValidity = () => ({
    type: appActionTypes.MBP_UI_RESET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
});

export const setValidatedZipcode = (zipcode) => ({
    type: appActionTypes.MBP_UI_SET_VALIDATED_ZIPCODE,
    payload: zipcode,
});

export const setValidatedStateOfZipcode = (state) => ({
    type: appActionTypes.MBP_UI_SET_VALIDATED_STATE_OF_ZIPCODE,
    payload: state,
});

export const clearValidatedZipcode = () => ({
    type: appActionTypes.MBP_UI_CLEAR_VALIDATED_ZIPCODE,
});

export const setUserSubmittedProductFilterZipcode = (zipcode) => ({
    type: appActionTypes.MBP_UI_SET_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
    payload: zipcode,
});

export const setValidZipcodeSubmmitedOnce = () => ({
    type: appActionTypes.MBP_UI_SET_VALID_ZIPCODE_SUBMITTED_ONCE,
});

export const setGuidedNavLockedProductClicked = () => ({
    type: appActionTypes.MBP_UI_SET_GUIDED_NAV_LOCKED_PRODUCT_CLICK,
});

export const clearUserSubmittedProductFilterZipcode = () => ({
    type: appActionTypes.MBP_UI_CLEAR_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
});

// wine verification
export const verifyWine = (wineData, addToCart = null, handleMobileAgeVerification = () => { }) => ({
    type: appActionTypes.WINE_VERIFY,
    payload: { wineData, addToCart, handleMobileAgeVerification },
});

export const verifyWineDone = (wineData) => ({
    type: appActionTypes.WINE_VERIFY_DONE,
    payload: wineData,
});

export const verifyWineFailed = (exception) => ({
    type: appActionTypes.WINE_VERIFY_FAILED,
    payload: exception,
});

export const setWineData = (wineData) => ({
    type: appActionTypes.MBPPRODUCT_SET_PDP_WINE,
    payload: wineData,
});

export const resetWineData = () => ({
    type: appActionTypes.MBPPRODUCT_RESET_PDP_WINE,
});

// The following two actions set and clear the delivery location type (Residence, Hospital, etc)
// This location type can be submitted by the user when searching for products and will be used to autofill
// various fields.
export const setLocationType = (location) => ({
    type: appActionTypes.MBP_UI_SET_LOCATION_TYPE,
    payload: location,
});

export const clearLocationType = () => ({
    type: appActionTypes.MBP_UI_CLEAR_LOCATION_TYPE,
});

export const setProductFilterOccasion = (name, code, occasionCode) => ({
    type: appActionTypes.MBP_UI_SET_PRODUCT_FILTER_OCCASION,
    payload: {
        name,
        code,
        occasionCode,
    },
});

export const setProductFilterProfile = (name, relationship, relationshipName) => ({
    type: appActionTypes.MBP_UI_SET_PRODUCT_FILTER_PROFILE,
    payload: {
        name,
        relationship,
        relationshipName,
    },
});

export const clearProductFilterOccasion = () => ({
    type: appActionTypes.MBP_UI_CLEAR_PRODUCT_FILTER_OCCASION,
});

export const setUserSelectedAddress = (address) => ({
    type: appActionTypes.MBP_UI_SET_USER_SELECTED_ADDRESS,
    payload: address,
});

export const setAddressSource = (address) => ({
    type: appActionTypes.MBP_UI_SET_ADDRESS_SOURCE,
    payload: address,
});

export const clearUserSelectedAddress = () => ({
    type: appActionTypes.MBP_UI_CLEAR_USER_SELECTED_ADDRESS,
});

export const setBannerCode = (content) => ({
    type: appActionTypes.APP_SET_BANNER_CODE,
    payload: content,
});
export const clearBannerCode = () => ({
    type: appActionTypes.APP_CLEAR_BANNER_CODE,
});

export const passportBundleItemAddedToCart = (sku) => ({
    type: appActionTypes.APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART,
    payload: sku,
});

export const passportBundleItemRemovedCart = (sku) => ({
    type: appActionTypes.APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART,
    payload: sku,
});

export const resetAgeVerifyFlag = () => ({
    type: appActionTypes.APP_RESET_AGE_FLAG,
});

export const setAgeVerifyFlagValid = (flag) => ({
    type: appActionTypes.APP_SET_AGE_FLAG_TRUE,
    payload: flag,
});

export const setAgeVerifyFlagInvalid = (flag) => ({
    type: appActionTypes.APP_SET_AGE_FLAG_FALSE,
    payload: flag,
});

export const setBrandMovieSkus = (skus) => ({
    type: appActionTypes.APP_SET_BRAND_MOVIE_SKUS,
    payload: skus,
});

export const setEmailSubscriptionData = (data) => ({
    type: appActionTypes.SET_EMAIL_SUBSCRIPTION_DATA,
    payload: data,
});

export const createEmailSubscription = (data) => ({
    type: appActionTypes.CREATE_EMAIL_SUBSCRIPTION,
    payload: data,
});

export const resetEmailSubscription = () => ({
    type: appActionTypes.RESET_EMAIL_SUBSCRIPTION_DATA,
});

export const setGCIData = (params) => ({
    type: appActionTypes.SET_GCI_DATA,
    payload: params,
});

export const clearGCIData = () => ({
    type: appActionTypes.CLEAR_GCI_DATA,
});

export const fetchGCIKey = (params) => ({
    type: appActionTypes.FETCH_GCI_KEY,
    payload: { params },
});

export const setGCIKey = (key) => ({
    type: appActionTypes.SET_GCI_KEY,
    payload: key,
});

export const setCountry = (params) => ({
    type: appActionTypes.SET_COUNTRY,
    payload: params,
});

export const setDonationsCategory = (params) => ({
    type: appActionTypes.SET_DONATIONS_CATEGORY,
    payload: params,
});

export const setSideMiniCart = (params) => ({
    type: appActionTypes.SET_SIDE_MINI_CART,
    payload: params,
});
export const setCartSliderOpened = (payload) => ({
    type: appActionTypes.SET_CART_SLIDER_OPEN,
    payload,
});
// The following two actions lock and unlock category pages. When locked, bahavior of various other components
// Such as SimpleProduct and GuidedNav change their behavior.
// export const lockCategoryPage = () => ({
//     type: appActionTypes.PAGE_LOCK_CATEGORY,
// });

// export const unlockCategoryPage = () => ({
//     type: appActionTypes.PAGE_UNLOCK_CATEGORY,
// });

export const setAddtoCartPosition = (position) => ({
    type: appActionTypes.APP_SET_ADDTOCART_POSITION,
    payload: position,
});

// Retail price
export const setProductRetailPrice = (data) => ({
    type: appActionTypes.APP_SET_PRODUCT_RETAIL_PRICE,
    payload: data,
});

export const setChatbotOpenState = (bool) => ({
    type: appActionTypes.APP_SET_CHATBOT_OPEN_STATE,
    payload: bool,
});

export const setScrollPosition = (data) => ({
    type: appActionTypes.SET_SCROLL_POSITION,
    payload: data,
});

// user confirmation for subscription modal popup by clicking on I understand link
export const setUserConfirmationForSubscriptionPopup = (bool) => ({
    type: appActionTypes.APP_SET_USER_CONFIRMATION_FOR_POPUP,
    payload: bool,
});

export const setGuidedNavModalHasBeenOpenedInSession = (bool) => ({
    type: appActionTypes.SET_GUIDED_NAV_MODAL_HAS_BEEN_LOADED,
    payload: bool,
});

export const setMiniCartUpsellState = (bool) => ({
    type: appActionTypes.APP_SET_MINICART_UPSELL_STATE,
    payload: bool,
});

export const setMiniCartModalState = (bool) => ({
    type: appActionTypes.APP_SET_MINICART_MODAL_STATE,
    payload: bool,
});

export const postChatEventWithEID = (data) => ({
    type: appActionTypes.CHAT_EVENTING_DATA,
    payload: data,
});

export const removeGCIKey = () => ({
    type: appActionTypes.REMOVE_GCI_KEY,
});

export const setTwillioMessageChatBot = (bool) => ({
    type: appActionTypes.APP_SET_TWILLIO_MESSAGE_CHAT_BOT,
    payload: bool,
});

export const subscriptionVerifyAddress = (data) => ({
    type: appActionTypes.SUBSCRIPTION_VERIFY_ADDRESS,
    payload: data,
});

export const setSubscriptionDetail = (data) => ({
    type: appActionTypes.SUBSCRIPTION_DETAIL,
    payload: data,
});

export const setAppPassportHoldoutGroup = (data) => ({
    type: appActionTypes.SET_APP_PASSPORT_HOLDOUT_GROUP,
    payload: data,
});

export const setAppPassportHoldoutGroupTracking = (data) => ({
    type: appActionTypes.SET_APP_PASSPORT_HOLDOUT_GROUP_TRACKING,
    payload: data,
});

export const setAbcChatLink = (data) => ({
    type: appActionTypes.SET_ABC_CHAT_LINK,
    payload: data,
});

export const setGuidedNavProductUnlock = (bool) => ({
    type: appActionTypes.APP_SET_GUIDED_NAV_PRODUCT_UNLOCK,
    payload: bool,
});

export const setWineAgeVerify = (bool) => ({
    type: appActionTypes.APP_SET_WINE_AGE_VERIFY,
    payload: bool,
});

export const setProductUnAvailable = (bool) => ({
    type: appActionTypes.APP_SET_PRODUCT_UN_AVAILABLE,
    payload: bool,
});

export const setSelectedInlineAddons = (params) => ({
    type: appActionTypes.APP_SET_SELECTED_INLINE_ADDONS,
    payload: params,
});

export const setLoginModuleBannerVisibility = (payload) => ({
    type: appActionTypes.SET_LOGIN_MODULE_VISIBILITY,
    payload,
});

export const setSelectedBYOItems = (params) => ({
    type: appActionTypes.APP_SET_SELECTED_BYO_ITEMS,
    payload: params,
});

export const setEmailCaptureStatus = (bool) => ({
    type: appActionTypes.APP_SET_EMAIL_CAPTURE_STATUS,
    payload: bool,
});

export const resetEmailOptInSFResponse = () => ({
    type: appActionTypes.APP_RESET_EMAIL_OPT_IN_SF_RESPONSE,
});

export const setEmailOptInSFResponse = (payload) => ({
    type: appActionTypes.APP_SET_EMAIL_OPT_IN_SF_RESPONSE,
    payload,
});

export const setEmailOptInRefetchTimestamp = (payload) => ({
    type: appActionTypes.APP_SET_EMAIL_OPT_IN_REFETCH_TIMESTAMP,
    payload,
});

export const setCacheProductData = (data) => ({
    type: appActionTypes.APP_SET_CACHE_PRODUCT_DATA,
    payload: data,
});
